import { createUseStyles } from "react-jss"
import { CLOSED_DRAWER_WIDTH } from "src/utils/constants"

export const usePageHeaderStyles = createUseStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: theme.spacing(1),
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    backgroundColor: theme.color.background.surfaceElevation0,
  },
  navMargin: {
    marginLeft: `calc(${theme.spacing(4)} + ${CLOSED_DRAWER_WIDTH}px)`,
    transition: "margin 0.5s",
  },
  navMarginSidebarOpen: { marginLeft: 0 },
  oneColumn: {
    gridTemplateColumns: "1fr",
  },
  descriptionContainer: {
    whiteSpace: "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    fontSize: 16,
    color: theme.color.text.primary,
  },
  actionsContainer: {
    justifySelf: "end",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    width: 2,
    height: 32,
    marginLeft: 10,
    backgroundColor: theme.color.background.surfaceElevation2,
  },
  searchContainer: {
    marginLeft: 10,
    minWidth: 370,
  },
  search: {
    height: 40,
    width: 40,
    borderRadius: "50%",
    backgroundColor: theme.color.tertiary[300],
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  button: {
    fontSize: 14,
  },
  dropdownBtn: {
    marginLeft: 10,
    fontSize: 14,
    paddingLeft: 19,
    paddingRight: 15,
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  popOverBtn: {
    marginBottom: 5,
  },
  popOverOpen: {
    transform: "rotate(180deg)",
    marginTop: 10,
  },
  tooltip: {
    backgroundColor: theme.color.primary.main,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: theme.color.primary.main,
  },

  refreshButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  refreshButtonContainer: {
    marginTop: -8,
    marginRight: 10,
  },
  refreshIntervalText: {
    fontSize: 14,
    fontWeight: 400,
    width: "max-content",
    color: theme.color.content.tertiary,
    lineHeight: 0.7,
    cursor: "auto",
    userSelect: "none",
  },
  refresh: {
    borderRadius: 4,
    border: `1px solid ${theme.color.border.accentDefault}`,
    display: "flex",
    width: 47,
    height: 47,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  clearTextClass: {
    marginRight: -10,
  },
  searchPostIconStyles: {
    marginRight: -10,
  },
  badgeWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconButton: { padding: 0 },
  chip: { display: "flex", marginLeft: 5 },
}))
