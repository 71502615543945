import { ENSkeleton } from "en-react/dist/src/components/Skeleton"
import { useSelector } from "react-redux"
import { RootState } from "src/store"
import { useDatGridLoadingComponentStyles } from "./DatGridLoadingComponent.styles"

const DataGridLoadingComponent: React.FC = (props: any) => {
  const classes = useDatGridLoadingComponentStyles()
  const colDefs = props?.api?.getColumnDefs()
  const isPolicyOrderInProgress = useSelector((state: RootState) => state.ui.isPolicyOrderInProgress)
  return isPolicyOrderInProgress ? null : (
    <div className={classes.root}>
      {colDefs?.map((item: any) => (
        <ENSkeleton width={Number(item?.width) - 20 || 50} height={24} />
      ))}
    </div>
  )
}

export default DataGridLoadingComponent
