import { ENAvatar } from "en-react/dist/src/components/Avatar"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENContextualMenu } from "en-react/dist/src/components/ContextualMenu"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENMenuItem } from "en-react/dist/src/components/MenuItem"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import appLogo from "src/assets/images/ztFullAppLogo.svg"
import { logoutEndUserOnly, logoutUser } from "src/pages/Login/Login.service"
import {
  AuthRoutesMap,
  END_USER_BASE_ROUTE_PREFIX,
  HomeRoutes,
  PrivateRoutesMap,
  PublicRoutesMap,
  Roles,
} from "src/routes/config"
import { isTenantRole, ProfileRoles } from "src/routes/config/Roles"
import useCatalogApps from "src/services/api/swrHooks/useCatalogApps"
import useDateTimeFormat from "src/services/api/swrHooks/useDateTimeFormat"
import useDevicePostures from "src/services/api/swrHooks/useDevicePostures"
import useProfile from "src/services/api/swrHooks/useProfile"
import useProfileImage from "src/services/api/swrHooks/useProfileImage"
import logger from "src/services/logger"
import ZtnaIcon from "src/shared/components/Icons"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { RootState } from "src/store"
import { resetAuth, setUser } from "src/store/auth"
import { User } from "src/store/auth/types"
import { setDateTimeFormat, setIsSideNavOpened, setLayout } from "src/store/ui"
import { openModal, setDevicePostureMessage, setToasterState, setUpgradeRelayState } from "src/store/ui/uiSlice"
import theme from "src/theme"
import { deleteCookie, getExternalApiUrl, getInitials, getXiqUrl } from "src/utils"
import { FEATURE_FLAGS_LOCAL_STORAGE_KEY } from "src/utils/constants"
import { CheckDevicePostureForBrowser, checkDevicePostureForBrowser } from "src/utils/devicePosture.utils"
import { useSWRConfig } from "swr"
import RelayNodesUpgradeDetailsModal from "../AppContainer/Banners/UpgradeBanner/RelayNodesUpgradeDetailsModal"
import RolloutUpgrade, { useRolloutUpgrade } from "../UpgradeRelayAndSC/RolloutUpgrade"
import { rolloutUpgrade } from "../UpgradeRelayAndSC/RolloutUpgrade/RolloutUpgrade.service"
import { useTopNavStyles } from "./TopNav.styles"

export let hideToaster: () => void

const TopNav: React.FC = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [open9DotsMenu, setOpen9DotsMenu] = useState<boolean>(false)
  const [selectedMenu, setSelectedMenu] = useState(false)
  const { name, email, sessionId, companyName } = useSelector((state: RootState) => state.auth.user)
  const role = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || "{}")?.role : ""
  const openedModals = useSelector((state: RootState) => state.ui.openedModals)
  const upgradeNotificationsData = useSelector((state: RootState) => state.ui.upgradeNotificationsData)
  const upgradeRelayState = useSelector((state: RootState) => state.ui.upgradeRelayState)
  const { data } = useProfileImage(role)
  const { data: userInfo, isLoading, error: profileError } = useProfile({ suspense: false }, role)

  const { data: dateTimeFormatData } = useDateTimeFormat(role)
  const { data: devicePostureData, getDevicePosture } = useDevicePostures({
    shouldFetch: role === Roles.END_USER,
    isEndUser: role === Roles.END_USER,
  })

  const { data: catalogApps, isLoading: isCatalogAppsLoading } = useCatalogApps({
    shouldFetch: isTenantRole(role),
  })
  const classes = useTopNavStyles({ isMobile, isCatalogAppsLoading })
  const { rolloutUpgradeData, getRolloutUpgrade } = useRolloutUpgrade()

  useEffect(() => {
    rolloutUpgradeData?.relayVersion && rolloutUpgrade({ relayVersion: rolloutUpgradeData?.relayVersion })
  }, [rolloutUpgradeData?.relayVersion])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isEndUser = role === Roles.END_USER
  const isCustomerAdmin = isTenantRole(role)
  const isSuperAdmin = role === Roles.SUPER_ADMIN

  const isEndUserPortal = pathname?.includes(END_USER_BASE_ROUTE_PREFIX) && role !== Roles.SUPER_ADMIN

  const { cache } = useSWRConfig()

  const userData = JSON.parse(localStorage.getItem("userData") || "{}")

  hideToaster = () => {
    dispatch(
      setToasterState({
        message: undefined,
        type: "success",
        autoHide: true,
      }),
    )
  }

  useEffect(() => {
    if (!userInfo && profileError?.response?.status === 401) {
      localStorage.removeItem("userData")
      localStorage.removeItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY)
      hideToaster()
      isEndUser || role === Roles.SUPER_ADMIN
        ? navigate(AuthRoutesMap.LOGIN.absolutePath)
        : navigate(PublicRoutesMap.XIQ_SESSION_EXPIRE.absolutePath)
    }
  }, [profileError])

  useEffect(() => {
    if (dateTimeFormatData?.dateFormat && dateTimeFormatData?.timeFormat) {
      const dateTimeFormat = {
        dateFormat: dateTimeFormatData.dateFormat,
        timeFormat: dateTimeFormatData.timeFormat,
        dateTimeFormat: `${dateTimeFormatData.dateFormat} ${dateTimeFormatData.timeFormat}`,
      }
      dispatch(setDateTimeFormat(dateTimeFormat))
    }
  }, [dateTimeFormatData?.dateFormat, dateTimeFormatData?.timeFormat])

  useEffect(() => {
    if (!isLoading) {
      const user = {
        email: userInfo?.email,
        role: ProfileRoles[userInfo?.role as keyof typeof ProfileRoles],
        sessionId,
        name: `${userInfo?.firstName} ${userInfo?.lastName}`,
        workspace: userInfo?.workspaceUrl,
        id: userInfo?.id,
        workspaceId: userInfo?.workspaceId,
        companyName,
        ownerId: userInfo?.ownerId,
        workspaceData: userData?.workspaceData,
      }
      dispatch(setUser(user as User))

      localStorage.setItem("userData", JSON.stringify(user))

      if (ProfileRoles[userInfo?.role as keyof typeof ProfileRoles] === ProfileRoles.TENANT_END_USER) {
        localStorage.setItem("sessionId", JSON.stringify(sessionId))
      }
    }
  }, [isLoading])

  const handleMenu = () => setOpenMenu(true)

  const handleClose = () => setOpenMenu(false)

  const handle9DotsMenu = () => {
    !isCatalogAppsLoading && setOpen9DotsMenu(true)
  }

  const handle9DotsClose = () => setOpen9DotsMenu(false)

  const resetUserData = (currentRoute: string) => {
    ;(cache as any).clear()
    localStorage.removeItem("isSideNavOpened")
    localStorage.removeItem("analyticsFilter")
    localStorage.removeItem("sessionId")
    localStorage.removeItem("userData")
    localStorage.removeItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY)
    dispatch(setLayout(undefined))
    dispatch(setIsSideNavOpened(false))
    dispatch(setUpgradeRelayState("pending"))
    deleteCookie("sessionid")
    deleteCookie("api_access_token")
    deleteCookie("oauth2AccessToken")
    hideToaster()
    if (isTenantRole(role) && !currentRoute.includes(END_USER_BASE_ROUTE_PREFIX)) {
      window.location.href = getXiqUrl()
    } else if (currentRoute.includes(END_USER_BASE_ROUTE_PREFIX)) {
      window.location.href = `${window.location.origin}${AuthRoutesMap.LOGIN.absolutePath}`
    } else
      navigate(
        role === Roles.SUPER_ADMIN ? AuthRoutesMap.ZTNA_ADMIN_LOGIN.absolutePath : AuthRoutesMap.LOGIN.absolutePath,
      )

    dispatch(resetAuth())
  }

  const logoutEndUserWhenPostureViolates = (browserPosture: CheckDevicePostureForBrowser) => {
    const { alternateBrowser, detectedBrowser, supportedVersions } = browserPosture
    logoutUser(role)
      .then(() => {
        resetUserData(window.location.pathname)
        dispatch(
          setDevicePostureMessage(
            `Your device does not comply with the browser policy enforced by the administrator.
             We support ${supportedVersions} of ${detectedBrowser}. If yours isn't supported, upgrade or consider ${alternateBrowser}. For further details, please contact your administrator.`,
          ),
        )
        dispatch(openModal("endUserLoggedOut"))
      })
      .catch((err) => logger.error("sign-out-btn", { error: err }))
  }

  // logout end user if applied posture violates browser conditions
  useEffect(() => {
    if (role === Roles.END_USER && devicePostureData) {
      // fetch posture data after set posture frequency
      if (devicePostureData?.frequency !== 0)
        setInterval(() => getDevicePosture(), devicePostureData?.frequency * 60000)
      const browserPoture = checkDevicePostureForBrowser(devicePostureData)

      const browserCheckNotMatching = devicePostureData && browserPoture?.devicePostureNotMatchingBrowser?.length

      if (devicePostureData?.isBrowserEnabled && browserCheckNotMatching > 0) {
        // when browser is enabled and not matching
        logoutEndUserWhenPostureViolates(browserPoture)
      }
    }
  }, [devicePostureData])

  const logoutXIQ = () => {
    logoutUser(role)
      .then(() => {
        if (isTenantRole(role)) {
          window.location.replace(`${localStorage.getItem("xiqLogoutUrl")}/sso/logout`)
        } else {
          resetUserData(window.location.pathname)
        }
      })
      .catch((err) => logger.error("sign-out-btn", { error: err }))
  }

  const onLogoutClick = () => {
    if (sessionId && (role === Roles.END_USER || role === Roles.SUPER_ADMIN)) {
      logoutEndUserOnly()
        .then(() => {
          resetUserData(window.location.pathname)
        })
        .catch(() => resetUserData(window.location.pathname))
    } else if (isTenantRole(role) && !sessionId) {
      logoutXIQ()
    } else if (isTenantRole(role) && sessionId) {
      logoutEndUserOnly().finally(() => {
        logoutXIQ()
      })
    } else {
      logoutEndUserOnly().finally(() => {
        resetUserData(window.location.pathname)
      })
    }
  }

  const onProfileClick = () => {
    logger.info("view-profile-btn")
    if (!pathname?.includes(PrivateRoutesMap.PROFILE.absolutePath)) {
      if (pathname?.includes(END_USER_BASE_ROUTE_PREFIX)) {
        navigate(`${PrivateRoutesMap.PROFILE.absolutePath}?redirect_url=${END_USER_BASE_ROUTE_PREFIX}`)
      } else {
        navigate(`${PrivateRoutesMap.PROFILE.absolutePath}`)
      }
    }
  }

  const isNewVersion = rolloutUpgradeData?.connectorVersion || rolloutUpgradeData?.radsecProxyVersion

  return (
    <>
      <nav className={classes.appBar}>
        <div className={classes.brand}>
          <div />
          <img className={classes.brandLogo} src={appLogo} alt="app-logo" />
        </div>

        {!isMobile && (
          <div className={classes.drawerControls}>
            {isCustomerAdmin && (
              <ENButton
                variant="tertiary"
                type="button"
                onClick={() => {
                  window.open("https://supportdocs.extremenetworks.com/support/documentation/uztna/", "_blank")
                }}
                className={classes.helpButton}
              >
                <ZtnaIcon name="enHelp" className={classes.helpIcon} size="xl" />
              </ENButton>
            )}

            {/* {isCustomerAdmin && (
              <>
                <ZtnaTooltip title="User Guide" arrow placement="bottom">
                  <ZtnaButton
                    buttonType="secondary"
                    className={classes.noTextTransform}
                    onClick={() => {
                      window.open(`http://${environmentUrl}/user-guide/`, "guideWindow")
                    }}
                    title="Need Help?"
                  />
                </ZtnaTooltip>
              </>
            )} */}

            {isCustomerAdmin && isEndUserPortal && (
              <>
                <ZtnaTooltip title="Tenant Admin Portal" arrow>
                  <Link
                    className={classes.portalIcon}
                    to={HomeRoutes[Roles.CUSTOMER_SUPER_ADMIN]}
                    target="tenantAdminPortalWindow"
                  >
                    <ZtnaIcon name="tenantAdminPortal" color={theme.color.neutralLight[16]} />
                  </Link>
                </ZtnaTooltip>
              </>
            )}

            {isSuperAdmin && upgradeRelayState !== "pending" && (
              <>
                <ENButton
                  styleModifier={classes.rolloutContainer}
                  onClick={() => {
                    dispatch(openModal("upgradeRelayNodeDetails"))
                  }}
                >
                  <ZtnaIcon
                    name={
                      upgradeNotificationsData.relayNodeUpgradeData?.status === "Upgrade Failed"
                        ? "upgradeFailed"
                        : "upgradeProgress"
                    }
                  />
                  <ENTextPassage>UZTNA Gateway Upgrade</ENTextPassage>
                </ENButton>
              </>
            )}

            {isSuperAdmin && (
              <>
                <ZtnaTooltip
                  title={!isNewVersion ? "No upgrades available at the moment" : "Upgrade available"}
                  placement="bottom"
                  arrow
                >
                  <ENButton
                    variant="tertiary"
                    styleModifier={classes.rolloutContainer}
                    isDisabled={!isNewVersion}
                    onClick={() => dispatch(openModal("rolloutUpgrade"))}
                  >
                    <ZtnaIcon name="encircledArrowUp" disabled={!isNewVersion} />
                    {isNewVersion && <div id="rolloutDot" />}
                  </ENButton>
                </ZtnaTooltip>
              </>
            )}

            <ENContextualMenu
              isActive={!!openMenu}
              toggleActive={() => {
                setOpenMenu(!openMenu)
              }}
              close={handleClose}
              style={{ gap: 0 }}
            >
              <ENAvatar
                style={{ cursor: "pointer", alignSelf: "center" }}
                badgeVariant="success"
                hasBadge
                slot="trigger"
                onClick={handleMenu}
              >
                <ZtnaTooltip arrow title={name} placement="bottom">
                  {data?.profileImage ? (
                    <div style={{ display: "flex" }}>
                      <img src={data ? data.profileImage : ""} alt="Profile" className={classes.userProfileImage} />
                    </div>
                  ) : (
                    getInitials(name)
                  )}
                </ZtnaTooltip>
              </ENAvatar>
              {openMenu && (
                <div className={classes.contextMenu}>
                  <div
                    className={classes.contextMenuTab}
                    // setSelected={() => setSelectedMenu(false)}
                    // isSelected={selectedMenu}
                    // id="en-ztna-topNav-profileImage-0"
                  >
                    {/* <ENAvatar aria-describedby="avatar">
                      {data?.profileImage ? (
                        <img
                          src={
                            role === Roles.END_USER
                              ? userInfo
                                ? userInfo.profileImage
                                : ""
                              : data
                              ? data.profileImage
                              : ""
                          }
                          alt="Profile"
                        />
                      ) : (
                        getInitials(name)
                      )}
                    </ENAvatar> */}

                    <ENTextPassage style={{ textAlign: "left" }}>
                      <div className={classes.menuUserName}>{name}</div>
                      <ZtnaTooltip
                        arrow
                        title={email}
                        placement="top"
                        cssPosition="absolute"
                        customStyle={{ marginTop: "0" }}
                      >
                        <div className={classes.menuEmail}>{email}</div>
                      </ZtnaTooltip>
                    </ENTextPassage>
                  </div>
                  <ENDivider />

                  <>
                    <div className={classes.contextMenuTab}>
                      <ENMenuItem
                        setSelected={() => {
                          setSelectedMenu(false)
                        }}
                        isSelected={selectedMenu}
                        onClick={() => {
                          onProfileClick()
                          handleClose()
                        }}
                        id="en-ztna-topNav-profileButton-1"
                      >
                        <ZtnaIcon name="enUser" />
                        View Profile
                      </ENMenuItem>
                    </div>
                    <ENDivider />
                  </>

                  <div className={classes.contextMenuTab}>
                    <ENMenuItem isSelected={false} id="en-ztna-topNav-logoutButton-3" isHeader={true}>
                      <div
                        className={classes.logoutItem}
                        onClick={() => {
                          onLogoutClick()
                        }}
                      >
                        <ZtnaIcon name="enSignOut" />
                        Log Out
                      </div>
                    </ENMenuItem>
                  </div>
                </div>
              )}
            </ENContextualMenu>

            {isTenantRole(role) && (
              <ENContextualMenu
                isActive={!!open9DotsMenu}
                toggleActive={() => {
                  setOpen9DotsMenu(!open9DotsMenu)
                }}
                close={handle9DotsClose}
                style={{ gap: 0 }}
              >
                <div className={classes.extremeNavigation} slot="trigger" onClick={handle9DotsMenu}>
                  <div className={classes.appsNavigation}>
                    <ZtnaIcon name="extremeAppsNavigation" />
                  </div>
                </div>
                {open9DotsMenu && !isCatalogAppsLoading && (
                  <>
                    {catalogApps?.length && <span className={classes.appsHeader}>Apps</span>}
                    <div className={classes.appsMenu}>
                      {catalogApps?.length ? (
                        catalogApps
                          ?.filter((app) => app.appLicense || app.appMgmt)
                          .map((item) => (
                            <div
                              onClick={() => {
                                if (item.appUrl.includes("http")) {
                                  window.open(item.appUrl)
                                } else {
                                  window.open(`${getExternalApiUrl()}${item.appUrl}`)
                                }
                              }}
                              key={item.id}
                              className={classes.contextItem}
                            >
                              <span className={classes.menuText}>{item.appName}</span>
                            </div>
                          ))
                      ) : (
                        <span className={classes.menuText}>No apps found.</span>
                      )}

                      <ENDivider />
                    </div>
                    <span
                      onClick={() => {
                        window.open(`${getExternalApiUrl()}/discovery-apps`)
                      }}
                      className={classes.moreAppsButton}
                    >
                      More Apps
                      <ZtnaIcon name="enChevronRight" size="lg" style={{ color: theme.color.neutralLight[16] }} />
                    </span>
                  </>
                )}
              </ENContextualMenu>
            )}
          </div>
        )}

        {isMobile && (
          <ENButton variant="tertiary" onClick={onLogoutClick} className={classes.logoutBtn}>
            <ZtnaIcon name="logout" color={theme.color.error.main} width="22px" height="22px" />
          </ENButton>
        )}

        <div className={classes.modalContainer}>
          {openedModals["rolloutUpgrade"] && rolloutUpgradeData && (
            <RolloutUpgrade {...rolloutUpgradeData} getRolloutUpgrade={getRolloutUpgrade} />
          )}
          {openedModals["upgradeRelayNodeDetails"] && <RelayNodesUpgradeDetailsModal />}
        </div>
      </nav>
    </>
  )
}

export default TopNav
