import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useSelector } from "react-redux"
import useLicense from "src/services/api/swrHooks/useLicense"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import { RootState } from "src/store"
import Banner from "../Banner"
import { useLicenseValidationBannerStyle } from "./LicenseValidationBanner.style"

const LicenseValidationBanner: React.FC = () => {
  const { showLicenseValidation: showBanner } = useSelector((state: RootState) => state.ui)
  const classes = useLicenseValidationBannerStyle()

  const { data: licenseData } = useLicense()

  return showBanner && licenseData?.licenseShortage ? (
    <Banner
      message={
        <div className={classes.messageContainer}>
          <div className={classes.icon}>
            <ZtnaIcon name="enWarningTriangle" size="lg" />
          </div>
          <ENTextPassage className={classes.message}>{licenseData?.errorMessage}</ENTextPassage>
        </div>
      }
      buttons={[
        {
          title: "Purchase License",
          handleClick: () => window.open(licenseData?.licenseLandingPageUrl, "_blank"),
        }
      ]}
    />
  ) : (
    <></>
  )
}
export default LicenseValidationBanner
