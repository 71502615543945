import { createUseStyles } from "react-jss"

export const useRetryConnectorInstanceUpgradeStyles = createUseStyles((theme) => ({
  contentClass: {
    minHeight: 350,
    "&.MuiDialogContent-root:first-child": {
      padding: "16px 25px 28px 24px",
    },
  },
  root: { display: "flex", flexDirection: "column", gap: 10 },
  infoContainer: {
    display: "flex",
    gap: 15,
    padding: 17,
    border: `1px solid ${theme.color.background.surfaceElevation2}`,
    borderRadius: 8,
  },
  iconWrapper: {
    marginTop: 5,
  },
  leftBorder: {
    paddingLeft: 11,
    borderLeft: `1px solid ${theme.color.content.tertiary}`,
  },
  commandContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
  },
  text: {
    color: theme.color.content.secondary,
    fontSize: 14,
  },
}))
