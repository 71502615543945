import { AbilityBuilder, AbilityClass, createMongoAbility, MongoAbility, PureAbility } from "@casl/ability"
import { createContextualCan, useAbility } from "@casl/react"
import { createContext } from "react"
import { Roles, RoleValueType } from "src/routes/config"

type RoleBasedActions = "view" | "edit" | "generate" | "manage"
type Subjects =
  | "completePopoverMenu"
  | "partialPopoverMenu"
  | "all"
  | "cardRowButton"
  | "CTA"
  | "pageHeaderButton"
  | "selectIdpButton"
  | "splunkForm"
  | "authToken"
  | "devicePostureSetting"
  | "inviteUserButton"
  | "removeDevicesOption"
  | "gridOrdering"
  | "networkPolicyEvaluationOption"

export type AppAbilityType = MongoAbility<[RoleBasedActions, Subjects]>
export const AppAbility = PureAbility as AbilityClass<AppAbilityType>

export function defineAbilityFor(role: RoleValueType) {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)

  if (([Roles.CUSTOMER_SUPER_ADMIN, Roles.SUPER_ADMIN, Roles.END_USER] as RoleValueType[]).includes(role)) {
    can("manage", "all")
    cannot("view", "partialPopoverMenu")
  } else {
    cannot("view", "completePopoverMenu")
    can("view", "partialPopoverMenu")

    cannot("view", "cardRowButton")
    cannot("view", "CTA")
    cannot("view", "pageHeaderButton")
    cannot("view", "selectIdpButton")
    cannot("view", "inviteUserButton")
    cannot("view", "removeDevicesOption")
    cannot("view", "splunkForm")
    cannot("edit", "splunkForm")
    cannot("edit", "gridOrdering")

    cannot("generate", "authToken")

    cannot("edit", "devicePostureSetting")
  }

  if (role === Roles.CUSTOMER_OBSERVER_ADMIN) {
    cannot("view", "networkPolicyEvaluationOption")
  } else {
    can("view", "networkPolicyEvaluationOption")
  }

  return rules
}

export function createAbility(role: RoleValueType): AppAbilityType {
  return createMongoAbility(defineAbilityFor(role), {
    detectSubjectType: (object) => object!,
  })
}

export const AbilityContext = createContext<AppAbilityType>(undefined!)
export default createContextualCan(AbilityContext.Consumer)
export const useAppAbility = () => useAbility(AbilityContext)
