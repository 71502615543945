import clsx from "clsx"
import { ENButtonGroup } from "en-react/dist/src/components/ButtonGroup"
import { ENDialog } from "en-react/dist/src/components/Dialog"
import { useState } from "react"
import ZtnaButton from "src/shared/components/ZtnaButton"
import CircularLoader from "../CicularLoader"
import { useModalStyles } from "./Modal.styles"
import { ModalProps } from "./Modal.types"

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  onCancel,
  onOk,
  onExport,
  children,
  title,
  onCancelProps,
  onExportProps,
  onOkProps,
  contentClass,
  showFooter = true,
  footerContent,
  width = 595,
  showBackIconButton = false,
  onBackIconClick,
  isWizard,
}) => {
  const {
    visible: cancelVisible = true,
    disabled: cancelDisabled,
    title: cancelTitle = "Cancel",
    onCancelOnly,
  } = onCancelProps || {}

  const { visible: exportVisible = true, title: exportTitle = "Export", onExportOnly } = onExportProps || {}

  const classes = useModalStyles()

  const [open, setOpen] = useState(true)

  return (
    <div className={classes.modalContainer}>
      {/* This `div` element can be removed in future if implemented within `en-react` library */}
      <ENDialog
        showBackIconButton={showBackIconButton}
        onBackButtonClick={onBackIconClick}
        heading={title.toString()}
        isActive={open}
        hasBackdrop
        // `handleOnKeydown` is being used to handle closing of modal on escape key
        handleOnKeydown={(event) => {
          if (event.key === "Escape") {
            setOpen(false)
            onCancel?.()
          }
        }}
        // `toggleActive` runs when clicked on cross icon
        toggleActive={() => onCancel?.()}
        disableClickOutside
        width={parseInt(`${width}`)} // We can remove this parseInt once no component is passing string value as width to <Modal />
        /* 
          The following props aren't working currently:
            // `open` runs only if we have a button inside Modal that triggers it
            open={() => console.log("opening modal")}
            // `handleOnClickOutside` not working at all
            handleOnClickOutside={() => console.log("clicked outside modal")}
            // `close` runs on every click outside modal
            close={() => console.log("closing modal")}
        */
      >
        <div className={clsx(classes.contentWrapper, contentClass)}>{children}</div>
        {footerContent}
        {showFooter && (
          <ENButtonGroup alignment="right" slot="footer">
            <>
              {onCancel && cancelVisible && (
                <ZtnaButton
                  buttonType="secondary"
                  dataTestid="modal-cancelButton"
                  id="en-ztna-modal-cancelButton"
                  disabled={cancelDisabled}
                  onClick={() => {
                    onCancel()
                    onCancelOnly && onCancelOnly()
                  }}
                  title={cancelTitle}
                />
              )}
              {onExport && exportVisible && (
                <ZtnaButton
                  buttonType="secondary"
                  dataTestid="modal-exportButton"
                  id="en-ztna-modal-exportButton"
                  onClick={() => {
                    onExport()
                    onExportOnly && onExportOnly()
                  }}
                  title={exportTitle}
                />
              )}
              <ZtnaButton
                buttonType={onOkProps?.buttonType || "primary"}
                title={onOkProps?.title || "Ok"}
                dataTestid="modal-saveButton"
                id={onOkProps?.id || "en-ztna-modal-saveButton"}
                disabled={onOkProps?.disabled || onOkProps?.isLoading}
                variant="outlined"
                startIcon={onOkProps?.isLoading && <CircularLoader isDark />}
                onClick={onOk}
              />
            </>
          </ENButtonGroup>
        )}
      </ENDialog>
    </div>
  )
}

export default Modal
